<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="news-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" :max-items="searchVar.itemsPerPage" :data="news">  <!-- multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="news">-->

      <div slot="header" class="flex flex-wrap items-center flex-grow">
        <v-select style="width: 150px;" placeholder="기간선택" @input="setDateRange" :options="dateRangeList"/>
        <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
        <v-select style="width: 100px;" placeholder="기자" label="nickname" :reduce="nickname => nickname.idx" v-model="writer_s"
          :options="writer"/>
        <v-select style="width: 100px" placeholder="상태" label="name" :reduce="name => name.code" v-model="state"
          :options="states"/>
        <v-select style="width: 100px" placeholder="카테고리" label="name" :reduce="name => name.code" v-model="category1"
          :options="categories1"/>
        <v-select style="width: 150px" placeholder="시리즈" label="name" :reduce="name => name.code" v-model="series"
          :options="seriesArray"/>
        <v-select style="width: 100px" placeholder="검색옵션" label="name" :clearable="false" v-model="searchType"
          :options="[{name: '제목', code: 'title'},{name: '본문', code: 'description'},{name: '기사번호', code: 'newsId'}]"/>
        <div style="padding-top: 0;">
          <vs-input class="inputx" placeholder="기사 검색" v-model="searchText" @keyup.enter="searchNewsList(searchVar)"/>
        </div>
        <vs-button style="width: 50px;" color="primary" type="border" class="p-2 cursor-pointer" @click="searchNewsList(searchVar)">검색</vs-button>
        <div  id="data-list-search-rank" style="padding-top: 0;">
          <div class="p-2 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * searchVar.itemsPerPage) + 1 }} - {{ numOfNews >= searchVar.itemsPerPage ? (currentx * searchVar.itemsPerPage) : numOfNews }} of {{ numOfNews }}</span>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th>기사 id</vs-th>
        <vs-th style="width: 45px;">후보</vs-th>
        <vs-th>기사 제목</vs-th>
        <vs-th style="width: 55px;">상태</vs-th>
        <vs-th style="min-width: 60px; letter-spacing: -2px;">포털송고</vs-th>
        <vs-th style="width: 90px;">송고시간<br>(작성시간)</vs-th>
        <vs-th style="width: 90px;">수정시간</vs-th>
        <vs-th style="width: 70px;">작성자</vs-th>
        <vs-th style="min-width: 60px; letter-spacing: -2px;">카테고리</vs-th>
        <vs-th>View</vs-th>
        <vs-th>포털</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td><p @click="showNews(indextr, tr.idx)">{{ tr.idx }}</p></vs-td>
          <vs-td><md-checkbox v-if="tr.news_free == 1" v-model="isHuboNews[indextr]" style="align: center"/><span v-else>유료</span></vs-td>
          <vs-td><div class="flex"><div v-if="activeUserInfo.level > 7 || ((activeUserInfo.id == tr.mno1 || activeUserInfo.id == tr.first_mno) && tr.state != 1)" style="padding:5px"><vs-button @click="editNews(tr.idx)" color="#FC7F03" type="line" size="small" icon-pack="feather" icon="icon-edit"></vs-button></div><div style="padding:10px" @click="showNews(indextr, tr.idx)"><span v-html="stateColor(tr.state, tr.title)"/><span v-if="tr.image_count > 0"><feather-icon style="margin-left: 5px; vertical-align: middle;" size="17" icon="ImageIcon"></feather-icon>{{tr.image_count}}</span></div></div></vs-td>
          <vs-td><span v-html="stateColor(tr.state, stateToText(tr.state))"/></vs-td>
          <vs-td v-if="tr.state == 1 && tr.exist_out_site == 'no' && activeUserInfo.level > 7"><div><vs-button type="border" color="#6593F5" icon-pack="feather" icon="icon-send" @click="sendNewsToPortal(tr)"></vs-button></div></vs-td>
          <vs-td v-else-if="activeUserInfo.level >= 9 && tr.state == 1 && tr.exist_out_site == 'yes'"><div><vs-button type="border" color="#ff0000" icon-pack="feather" icon="icon-trash" @click="tryDeleteNewsPortal(tr)"></vs-button></div></vs-td>
          <vs-td v-else><div></div></vs-td>
          <!-- <vs-td v-if="activeUserInfo.level != 10 && tr.state == 1 && tr.exist_out_site == 'yes'"><div><vs-button disabled type="border" color="#ff0000" icon-pack="feather" icon="icon-trash" @click="tryDeleteNewsPortal(tr)"></vs-button></div></vs-td> -->
          
          <vs-td v-tooltip="{content: '송고시간: ' +tr.d_pass2 + '<br>수정시간: ' + tr.d_modi2 + '<br>작성시간: ' + tr.d_write, placement: 'top'}">{{ tr.d_pass }}</vs-td>
          <vs-td>{{ tr.d_modi }}</vs-td>
          <vs-td>{{ tr.writer }}</vs-td>
          <vs-td>{{ tr.menu_code }}</vs-td>
          <vs-td>{{ tr.viewed }}</vs-td>
          <vs-td v-tooltip="{content: tr.out_site, placement: 'top'}"><span v-html="portalText(tr.out_site)"/></vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>

    <!-- Start: 뉴스 상세보기 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
        <div class="items-center">
          <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0;">
          <div style="margin:0 auto;">
            <vs-button v-if="activeUserInfo.level > 7 || ((activeUserInfo.id == news[selectedIndex].mno1 || activeUserInfo.id == news[selectedIndex].first_mno) && news[selectedIndex].state != 1)" vs-type="gradient" color="success" icon="create" @click="editNews(selectedId)">기사수정</vs-button>
          </div>
            <v-select v-if="activeUserInfo.level >= 9 && newsDetail.state == '1'" placeholder="포털 선택" label="name" v-model="portalSelect"
              multiple :options="$store.state.portalsWithAll" :closeOnSelect="false" style="width: 150px;">
            </v-select>
            <vs-button v-if="activeUserInfo.level >= 9 && newsDetail.state == '1'" vs-type="flat" color="warning" icon="delete_sweep" @click="deletePortalNews(portalSelect, selectedId)">포털삭제</vs-button>
            <vs-button v-if="activeUserInfo.level >= 9" vs-type="flat" color="danger" icon="delete_sweep" @click="deleteNews(selectedId, newsDetail.state)">기사삭제</vs-button>
            <!-- <vs-button v-if="activeUserInfo.level == 10 || (activeUserInfo.id == news[selectedIndex].mno && news[selectedIndex].state >= 8)" vs-type="flat" color="danger" icon="delete_sweep" @click="deleteNews(selectedId, newsDetail.state)">기사삭제</vs-button> -->
          </div>
          <div class="news-detail" id="view_con" style="margin: 20px 0px 0px;" v-html="newsDetail.data"></div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 뉴스 상세보기 팝업 -->

    <!-- Start: 포털 삭제 팝업 -->
    <div class="write-new-ad-form">
        <vs-popup classContent="popup-example" title="삭제 포털 선택" :active.sync="portalDeleteActive">

            <div v-for="(item, idx) in $store.state.portalsWithAll" v-bind:key="idx">
                <md-checkbox style="padding: 20px" v-model="selectedPortalsToDelete[idx]">{{ item.name }}</md-checkbox>
            </div>

            <vs-button class="p-2" style="width: 95%" type="border" color="primary" @click="deleteNewsPortal(); portalDeleteActive = false">선택삭제</vs-button>
            <vs-button class="p-2" style="width: 95%" type="border" color="danger" @click="deleteNewsPortalAll(); portalDeleteActive = false">전체삭제</vs-button>
        </vs-popup>
    </div>
    <!-- End: 포털 삭제 팝업 -->

  </div>
</template>

<script>
import vSelect from 'vue-select'
// import AddNewDataSidebar from '../AddNewDataSidebar.vue';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
import moment from 'moment';

export default {
  components: {
    'v-select': vSelect,
    // AddNewDataSidebar
    DateRangePicker,
  },
  data() {
    return {
      selectedPortalsToDelete: [],
      portalDeleteActive: false,
      selectedIndex: 0,
      selectedId: 0,
      news: [{descr: ''}],
      isHuboNews: [],
      isHuboNewsSnapshot: [],
      currentx: 1,
      numOfNews: 0,
      numOfPages: 0,
      sentNewsflashCnt: 0,
      searchVar: {
        startRow: 0,
        itemsPerPage: 15,
        state: null,
        category1: null,
        series: null,
        rank: null,
        writer: null,
        searchType: 'title',
        searchText: '',
        startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: moment().add(2, 'week').format('YYYY-MM-DD'),
        department: '',
        siteCode: '',
      },
      state: null,
      category1: null,
      series: null,
      rank: null,
      writer_s: null,
      searchType: {name: '제목', code: 'title'},
      searchText: '',
      dateRange: { // used for v-model prop
          startDate: moment().subtract(1, 'month'),
          endDate: moment().add(2, 'week'),
      },
      dateRange2: [new Date(moment().subtract(1, 'month')), new Date(moment().add(2, 'week'))],
      dateRangeList: ['최근6개월'],
      newsDetail: '',
      writer: [],
      types: [],
      states: [],
      categories1: [],
      categories1_ok: false,
      categories2: [],
      categories2_view: [],
      seriesArray: [],
      category2Disable: true,
      portals: [],
      portalSelect: [],
      isMounted: false,
      addNewDataSidebar: false,
      newsDetailModalActive: false,
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
          '오늘': [moment(), moment()],
          '1주일': [moment().subtract(1, 'week'), moment()],
          '한달': [moment().subtract(1, 'month'), moment()],
          '이번 달': [moment().startOf('month'), moment().endOf('month')],
          '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
    }
  },
  beforeRouteLeave (to, from, next) {
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    isHuboNews: function() {
      console.log('hubo', this.isHuboNews, this.isHuboNewsSnapshot)
      var thisIns = this

      for(var idx in this.isHuboNews) {
        
        if(this.isHuboNewsSnapshot[idx] != this.isHuboNews[idx]) {
          this.$http.post('/api/updateHuboNews', {id: this.news[idx].idx, register: this.isHuboNews[idx]})
            .then(function (response) {
              console.log(response.data);
              thisIns.$vs.notify({
                title:'성공',
                text: '후보지정 완료',
                color:'success',
                iconPack: 'feather',
                icon:'icon-alert-circle'
              })
            })
            .catch(function (error) {
              thisIns.$vs.notify({
                title:'Error',
                text: error,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
              thisIns.$vs.loading.close();  
            });
        }
        
        this.isHuboNewsSnapshot[idx] = this.isHuboNews[idx]
      }
    },
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
      else {
        this.searchVar.startRow = 0;
        this.currentx = 1;
        if(this.$route.query.startRow != 0){
          this.state= null;
          this.category1= null;
          this.series= null;
          this.rank= null;
          this.writer_s= null;
          this.searchText= '';
          this.dateRange= { // used for v-model prop
              startDate: moment().subtract(2, 'week'),
              endDate: moment().add(2, 'week'),
          }
        }
      }
      if(this.$route.query.state) this.searchVar.state = this.$route.query.state;
      else this.searchVar.state = null;
      if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
      else this.searchVar.category1 = null;
      if(this.$route.query.series) this.searchVar.series = this.$route.query.series;
      else this.searchVar.series = null;
      if(this.$route.query.rank) this.searchVar.rank = this.$route.query.rank;
      else this.searchVar.rank = null;
      if(this.$route.query.writer) this.searchVar.writer = this.$route.query.writer;
      else this.searchVar.writer = null;
      if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
      else this.searchVar.searchText = '';
      if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
      else this.searchVar.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
      else this.searchVar.endDate = moment().add(2, 'week').format('YYYY-MM-DD');
      if(this.$route.query.siteCode) this.searchVar.siteCode = this.$route.query.siteCode;
      else this.searchVar.siteCode = '';
      // if(this.$route.query.department || this.$route.query.department == '') this.searchVar.department = this.$route.query.department;
      // else this.searchVar.department = this.activeUserInfo.department;
      this.getNewsList(this.searchVar);
    },
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      let currentx = this.currentx;
      if(currentx <= 0){ currentx = 1 }
      this.searchVar.startRow = (currentx-1) * this.searchVar.itemsPerPage;
      window.scrollTo(0,0);
      console.log(this.searchVar)
      this.$router.push({path: '/newsListAll', query: this.searchVar})
      // this.getNewsList(this.searchVar);
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    // changeHuboNews(index){
    //   console.log(this.isHuboNews, this.isHuboNewsSnapshot)
    //   const thisIns = this;
    //   if(this.isHuboNews[index] == 0) this.isHuboNews[index] = 1;
    //   else this.isHuboNews[index] = 0;
    //   this.$http.post('/api/updateHuboNews', {id: this.news[index].id, register: this.isHuboNews[index]})
    //     .then(function (response) {
    //       console.log(response.data);
    //       thisIns.$vs.notify({
    //         title:'성공',
    //         text: '후보지정 완료',
    //         color:'success',
    //         iconPack: 'feather',
    //         icon:'icon-alert-circle'
    //       })
    //     })
    //     .catch(function (error) {
    //       thisIns.$vs.notify({
    //         title:'Error',
    //         text: error,
    //         color:'danger',
    //         iconPack: 'feather',
    //         icon:'icon-alert-circle'})
    //       thisIns.$vs.loading.close();  
    //     });
    //   this.isHuboNewsSnapshot[index] = this.isHuboNews[index]
    //   // this.news[index]
    // },
    portalText(data){
      // console.log(data)
      let portalText;
      var sendFlg = 0;
      if(data){
        data.split("|").forEach(sended =>{
          if(this.$store.state.portals.find(e => e.name.toLowerCase() == sended.toLowerCase() || e.oldName == sended)){
            sendFlg++;
          }
        })
        if(sendFlg >= this.$store.state.portals.length) {
          portalText = "완";
        }else if(sendFlg > 0){
          portalText = "미완";
        }else{
          portalText = "";
        }
      }else{
        portalText = "";
      }
      return(portalText);
    },
    stateToText(state){
      let stateText;
      switch(state)
      {
        case '*': stateText ="상태"; break;
        case '0': stateText ="보류"; break;
        case '1': stateText ="송료"; break;
        case '2': stateText ="송예"; break;
        case '3': stateText ="편료"; break;
        case '4': stateText ="편중"; break;
        case '8': stateText ="작료"; break;
        case '9': stateText ="작중"; break;
      }
      return(stateText);
    },
    stateColor(state, text){
      let stateText;
      switch(state)
      {
        case '0': stateText ="<font color='black'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '1': stateText ="<font color='blue'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '2': stateText ="<font color='#333300'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '3': stateText ="<font color='red'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '4': stateText ="<font color='#666600'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '8': stateText ="<font color='#666600'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '9': stateText ="<font color='darkviolet'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
      }
      return(stateText);
    },
    showNews(index, newsId){
      this.newsDetailModalActive = true;
      this.selectedIndex = index;
      this.selectedId = newsId;
      console.log(this.selectedIndex, this.selectedId);
      this.newsDetail = '';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getSingleNews/detail', {id: newsId})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
          // var images = response.data[1];
          // for(var i=0;i<images.length;i++){
          //   thisIns.sendData.arrayBase64.push(images[i].img);
          //   thisIns.sendData.arrayImageText.push(images[i].img_c);
          //   thisIns.sendData.arrayImageAlign.push(images[i].align);
          //   thisIns.sendData.arrayImageWidth.push(images[i].width);
          // }
          // var tags = response.data[2];
          // for(var i=0;i<tags.length;i++){
          //   thisIns.sendData.tag.push(tags[i].tag);
          // }
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    editNews(newsId){
      console.log("edit: " + newsId);
      this.newsDetailModalActive = false;
      this.$router.push({path: '/writeArticles', query: { id: newsId}});
    },
    tryDeleteNewsPortal(item) {
      
      this.itemToDelPortal = item
      this.selectedPortalsToDelete = []
      this.portalDeleteActive = true
    },
    deleteNewsPortal() {

      var selectedPortalsToDel = []
      for(var idx in this.selectedPortalsToDelete) {

        if(this.selectedPortalsToDelete[idx]) {
          selectedPortalsToDel.push(this.$store.state.portalsWithAll[idx])
        }
      }
      if(selectedPortalsToDel.find(e => e.name == 'ALL')){
        selectedPortalsToDel = []
        for(idx in this.$store.state.portalsWithAll) {
            selectedPortalsToDel.push(this.$store.state.portalsWithAll[idx])
        }
      }

      var thisIns = this

      var newsTitle = this.itemToDelPortal.title
      console.log(selectedPortalsToDel)
      // var newsTitle = 'hello'
      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `포털 선택 삭제`,
        text: `'${newsTitle}'를(을) 포털 삭제 하시겠습니까?`,
        accept: function() {
          
          thisIns.deletePortalNews(selectedPortalsToDel, thisIns.itemToDelPortal.idx)
        }
      })
    },
    deleteNewsPortalAll() {

      var selectedPortalsToDel = []

      for(var idx in this.$store.state.portalsWithAll) {

          selectedPortalsToDel.push(this.$store.state.portalsWithAll[idx])
      }

      var thisIns = this

      var newsTitle = this.itemToDelPortal.title
      console.log(selectedPortalsToDel)

      // var newsTitle = 'hello2'
      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `포털 전체 삭제`,
        text: `'${newsTitle}'를(을) 포털 삭제 하시겠습니까?`,
        accept: function() {
          
          thisIns.deletePortalNews(selectedPortalsToDel, thisIns.itemToDelPortal.idx)
        }
      })
    },
    sendNewsflash(newsTitle, newsId) {
      var thisIns = this
      
      console.log('newsId:', newsId)

      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: `속보 전송 (${5 - thisIns.sentNewsflashCnt >= 0 ? 5 - thisIns.sentNewsflashCnt : 0}회 남음)`,
        text: `'${newsTitle}'를(을) 속보로 보내시겠습니까?`,
        accept: function() {

          thisIns.$http.post('/api/sendNewsflash', {newsId: newsId})
            .then(function (response) {

              console.log(response)

              if(response.data.is_sent) {

                thisIns.sentNewsflashCnt++

                thisIns.$vs.notify({
                  title:'속보 전송 완료',
                  text: `'${newsTitle}'`,
                  color:'success',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
              }
              else {

                if(response.data.result == 'send_limit_exceed') {

                  thisIns.$vs.notify({
                    title:'속보 전송 실패',
                    text: `금일 전송 한도를 초과하였습니다.`,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                }
                else if(response.data.result == 'portal_upload_failed') {

                  thisIns.$vs.notify({
                    title:'속보 전송 실패',
                    text: `속보 전송 중 오류가 발생했습니다.`,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                }
                else {

                  thisIns.$vs.notify({
                    title:'속보 전송 실패',
                    text: `알 수 없는 오류로 인해 전송에 실패했습니다.`,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                }
              }
            })
            .catch(function (error) {
              
                thisIns.$vs.notify({
                  title:'Error',
                  text: error,
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
                thisIns.$vs.loading.close();  
            });
        }
      })
    },
    sendNewsToPortal(item) {

      var thisIns = this

      var newsId = item.idx
      var newsTitle = item.title
      
      console.log('newsId:', newsId)

      thisIns.$vs.dialog({
        type: 'confirm',
        color: '#6593F5',
        title: `포털 송고`,
        text: `'${newsTitle}'를(을) 포털로 송고하시겠습니까?`,
        accept: function() {

          this.$http.post('/api/quickPortalSend', {newsId: newsId})
          .then(function (res) {

            console.log('res)', res.data)

            if(res.data.status == 'success') {

              item.state = '1'
              thisIns.getNewsList(thisIns.searchVar);

              thisIns.$vs.notify({
                title:'포털 송고 완료',
                text: `'${newsTitle}'`,
                color:'success',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
            }
            else {

              thisIns.$vs.notify({
                title: `오류`,
                text: `알 수 없는 오류로 인해 포털 송고에 실패했습니다`,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
            }
          })
          .catch(function (error) {
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();
          });
        }
      })
    },
    
    deletePortalNews(selectedPortal, newsId){
      if(selectedPortal.find(e => e.name == 'ALL')){
        selectedPortal = []
        for(const portal of this.portals) {
            selectedPortal.push(portal)
        }
      }
      for(let item of selectedPortal){
        item['action'] = "D";
      }
      console.log("delete: " + newsId, selectedPortal);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/writeArticles/portalDelete', {'id': newsId, 'portalAction': selectedPortal})
        .then(function (res) {
          thisIns.$vs.notify({
            title:'성공',
            text: '포털 삭제 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          console.log(res);
          thisIns.newsDetailModalActive = false;
          thisIns.getNewsList(thisIns.searchVar);
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();
        });
    },
    deleteNews(newsId, state){
      if(confirm(`${newsId}번 기사를 삭제하시겠습니까?`)){
        console.log("delete: " + newsId, state);
        this.$vs.loading({
          scale: 1.5
        });
        const thisIns = this;
        let delFlg = false;
        if(state == 1){ delFlg = true }
        this.$http.post('/api/writeArticles/delete', { id: newsId, delFlg })
          .then(function (res) {
            thisIns.$vs.notify({
              title:'성공',
              text: '기사 삭제 성공',
              color:'primary',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
              console.log(res);
              thisIns.newsDetailModalActive = false;
              thisIns.getNewsList(thisIns.searchVar);
              thisIns.$vs.loading.close();  
          })
          .catch(function (error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
            }
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();
          });
      }
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      // this.getNewsList(this.searchVar)
    },
    searchNewsList(data){
      data.startRow = 0;
      // console.log(this.state)
      data.state = this.state;
      data.category1 = this.category1;
      data.series = this.series;
      data.rank = this.rank;
      data.writer = this.writer_s;
      data.searchText = this.searchText;
      data.searchType = this.searchType.code;
      data.startDate = moment(this.dateRange2[0]).format('YYYY-MM-DD');
      data.endDate = moment(this.dateRange2[1]).format('YYYY-MM-DD');
      data.department = '';
      this.$router.push({path: '/newsListAll', query: data})
      this.currentx = 1;
      // this.getNewsList(data);
    },
    getNewsList(data) {
      console.log(data.state, data.category1, data.writer, data.series)

      data.level = this.activeUserInfo.level
      data.id = this.activeUserInfo.id
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getNewsList', data)
        .then(function (response) {
          // console.log(response);
          if(response.data[0][0].news_count){
            thisIns.numOfNews = response.data[0][0].news_count;
            thisIns.numOfPages = Math.ceil(response.data[0][0].news_count / thisIns.searchVar.itemsPerPage);
            thisIns.news = response.data[1];
            // thisIns.sentNewsflashCnt = response.data[2][0].cnt

            for(var idx in thisIns.news) {
              thisIns.isHuboNews[idx] = thisIns.news[idx].is_hubo ? true : false
              thisIns.isHuboNewsSnapshot[idx] = thisIns.news[idx].is_hubo ? true : false
            }

            // for(var i=0; i< thisIns.news.length;i++){
            //   // console.log(response.data[2][i].length);
            //   thisIns.news[i]['menu_code'] = "";
            //   if(thisIns.news.length > 1){
            //     for(var j=0;j<response.data[2][i].length;j++){
            //       thisIns.news[i]['menu_code'] += response.data[2][i][j].name + "| "
            //     }
            //   }else{
            //     for(var j=0;j<response.data[2].length;j++){
            //       thisIns.news[i]['menu_code'] += response.data[2][j].name + "| "
            //     }
            //   }
            //   thisIns.news[i]['menu_code'] = thisIns.news[i]['menu_code'].substr(0,thisIns.news[i]['menu_code'].length-1);
            //   // if(data.category1){
            //   //   data.menu_code = '';
            //   //   data.category1.split(';').forEach(function (data, category) {
            //   //     if(category){
            //   //       // console.log('category: ', category, data);
            //   //       data.menu_code += thisIns.categories1.find(_category => _category.code == category).name + '|';
            //   //     }
            //   //   }.bind(null, data))
            //   //   data.menu_code = data.menu_code.slice(0,-1)
            //   // }
            // }
          }else{
            thisIns.numOfNews = 0;
            thisIns.numOfPages = 0;
            thisIns.news = [{descr: ''}];
          }
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    getOrderStatusColor(status) {
      if(status == 'on hold') return "warning"
      if(status == 'delivered') return "success"
      if(status == 'canceled') return "danger"
      return "primary"
    },
    getPopularityColor(num) {
      if(num > 90) return "success"
      if(num >70) return "primary"
      if(num >= 50) return "warning"
      if(num < 50) return "danger"
      return "primary"
    },
    setDateRange(data){
      if(data){
        if(data.match('최근')){
          this.dateRange2 =  [new Date(moment().subtract(6, 'month')), new Date(moment().add(2, 'week'))];
        }else{
          data = data.replace('년', '')
          console.log(data, moment(data+'-01-01'))
          this.dateRange2 =  [new Date(moment(data+'-01-01')), new Date(moment(data+'-12-31'))];
        }
      }
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.categories1 = [{code: '', name: '전체'}].concat(this.$store.state.newCategories1);
    console.log(this.categories1)
    this.states = this.$store.state.states;
    this.writer = this.$store.state.writer;
    this.seriesArray = this.$store.state.series;
    this.portals = this.$store.state.portals;
    // console.log(this.$store.state.portals);
    const tmpPage = parseInt(this.$route.query.startRow / this.$route.query.itemsPerPage + 1);
    if(tmpPage > 1) this.currentx = tmpPage;
    this.searchVar.department = '';
    if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
    if(this.$route.query.state) this.searchVar.state = this.$route.query.state;
    if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
    if(this.$route.query.series) this.searchVar.series = this.$route.query.series;
    if(this.$route.query.rank) this.searchVar.rank = this.$route.query.rank;
    if(this.$route.query.writer) this.searchVar.writer = this.$route.query.writer;
    if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
    if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
    if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
    if(this.$route.query.siteCode) this.searchVar.siteCode = this.$route.query.siteCode;
    // if(this.$route.query.department || this.$route.query.department == '') this.searchVar.department = this.$route.query.department;
    // console.log('department: ', this.activeUserInfo.department);
    this.getNewsList(this.searchVar);
    for(let i=0; i < 10; i++){
      this.dateRangeList.push(moment().subtract(i, 'years').format('YYYY') + '년');
    }
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-newslist.scss";
</style>
